<template>
  <div class="contentConfigParamsFraisTh">
    <div class="entete">
      <div class="box-setting-header ">
        <div class="title">Gestion des variables frais</div>
        <div>
          <!-- <b-button
            variant="success"
            class="config-btn ml-2"
            v-b-modal.ConfigurationParamsFrais
            ><font-awesome-icon icon="plus" /> Nouvelle configuration</b-button
          > -->
          <b-modal
            id="ConfigurationParamsFrais"
            ref="ConfigurationParamsFrais"
            title="Nouvelle configuration"
            no-close-on-backdrop
            :hide-footer="true"
            :hide-header="true"
            @hidden="resetModal"
            modal-class="cutsom-modal-bootstrap custom-modal-size"
          >
            <div class="hader mb-2">
              <div class="titleSetting">Nouvelle configuration</div>
              <div
                class="iconClose"
                @click.prevent="hideModal('ConfigurationParamsFrais')"
              >
                <font-awesome-icon icon="times" />
              </div>
            </div>
            <Card>
              <template v-slot:body>
                <form
                  @submit.prevent="handleSubmitConfigParamsFrais"
                  class="form-modal-custom-style"
                >
                  <div class="ModelCol mr-3">
                    <b-form-group
                      label="Année"
                      label-for="year"
                      class=" input-modal-champ "
                    >
                      <b-form-select
                        id="year"
                        v-model="FraisToConfig.year"
                        :options="ListYear"
                        required
                        @change="handleChangeYearModel"
                        class="b-form-select-raduis "
                      ></b-form-select>
                    </b-form-group>
                    <b-form-group
                      label="Mois"
                      label-for="month"
                      class=" input-modal-champ "
                    >
                      <b-form-select
                        id="month"
                        v-model="FraisToConfig.month"
                        :options="computedGetMonthForYearTh"
                        required
                        text-field="text"
                        value-field="value"
                        class="b-form-select-raduis "
                      ></b-form-select>
                    </b-form-group>
                    <b-form-group
                      label="Semaine"
                      label-for="week"
                      class=" input-modal-champ "
                    >
                      <b-form-select
                        id="week"
                        v-model="FraisToConfig.week"
                        :options="computedListWeekModal"
                        text-field="text"
                        value-field="value"
                        class="b-form-select-raduis "
                      ></b-form-select>
                    </b-form-group>
                  </div>
                  <div class="ModelCol">
                    <b-form-group
                      label="Opérationnel %"
                      label-for="Opérationnel"
                      class="input-modal-champ "
                    >
                      <b-form-input
                        autocomplete="off"
                        id="Opérationnel"
                        v-model="FraisToConfig.operationnel"
                        required
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group
                      label="Commercial %"
                      label-for="Commercial"
                      class="input-modal-champ "
                    >
                      <b-form-input
                        autocomplete="off"
                        id="Commercial"
                        v-model="FraisToConfig.commercial"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Marketing %"
                      label-for="marketing"
                      class="input-modal-champ "
                    >
                      <b-form-input
                        autocomplete="off"
                        id="marketing"
                        v-model="FraisToConfig.marketing"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="ModelCol">
                    <b-form-group
                      label="Technique %"
                      label-for="technique"
                      class="input-modal-champ "
                    >
                      <b-form-input
                        autocomplete="off"
                        id="technique"
                        v-model="FraisToConfig.technique"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="RGE %"
                      label-for="RGE"
                      class="input-modal-champ "
                    >
                      <b-form-input
                        autocomplete="off"
                        id="RGE"
                        v-model="FraisToConfig.rge"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="CDG %"
                      label-for="CDG"
                      class="input-modal-champ "
                    >
                      <b-form-input
                        autocomplete="off"
                        id="CDG"
                        v-model="FraisToConfig.CDG"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>

                  <div class="ModelCol">
                    <b-form-group
                      label="administratif SAV %"
                      label-for="administratif_sav"
                      class="input-modal-champ "
                    >
                      <b-form-input
                        autocomplete="off"
                        id="administratif_sav"
                        v-model="FraisToConfig.administratif_sav"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="DSI %"
                      label-for="DSI"
                      class="input-modal-champ "
                    >
                      <b-form-input
                        autocomplete="off"
                        id="DSI"
                        v-model="FraisToConfig.dsi"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Juridique %"
                      label-for="juridique"
                      class="input-modal-champ "
                    >
                      <b-form-input
                        autocomplete="off"
                        id="juridique"
                        v-model="FraisToConfig.juridique"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="ModelCol">
                    <b-form-group
                      label="Alger %"
                      label-for="alger"
                      class="input-modal-champ "
                    >
                      <b-form-input
                        autocomplete="off"
                        id="alger"
                        v-model="FraisToConfig.alger"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Alteo contrôle qualité"
                      label-for="controle_gestion"
                      class="input-modal-champ "
                    >
                      <b-form-input
                        autocomplete="off"
                        id="controle_gestion"
                        v-model="FraisToConfig.alteo_control_gestion"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Upcube %"
                      label-for="Upcube"
                      class="input-modal-champ "
                    >
                      <b-form-input
                        autocomplete="off"
                        id="Upcube"
                        v-model="FraisToConfig.upcube"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>

                  <div class="ModelCol">
                    <b-form-group
                      label="Bureau d'étude (Forfaitaire)"
                      label-for="bureau_detude"
                      class="input-modal-champ "
                    >
                      <b-form-input
                        autocomplete="off"
                        id="bureau_detude "
                        v-model="FraisToConfig.bureau_detude"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Mairie (Forfaitaire)"
                      label-for="mairie"
                      class="input-modal-champ "
                    >
                      <b-form-input
                        autocomplete="off"
                        id="mairie"
                        v-model="FraisToConfig.mairie"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Maintenace PAC (Forfaitaire)"
                      label-for="maintenance_pac"
                      class="input-modal-champ "
                    >
                      <b-form-input
                        autocomplete="off"
                        id="maintenance_pac "
                        v-model="FraisToConfig.maintenance_pac"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>

                  <div class="actionModel">
                    <div class="messageError">
                      <div v-if="error" class="error">
                        <ul v-if="Array.isArray(error)" class="mb-0">
                          <li v-for="(e, index) in error" :key="index">
                            {{ e }}
                          </li>
                        </ul>
                        <div v-else>{{ error }}</div>
                      </div>
                    </div>
                    <b-button type="submit" class="button-valide-style ">
                      <span>
                        Valider
                        <div v-if="loading" class="loading ml-2">
                          <div class="spinner-border" role="status"></div>
                        </div>
                      </span>
                    </b-button>
                  </div>
                </form>
              </template>
            </Card>
          </b-modal>
        </div>
      </div>
      <div class="ligne-box-setting-header mt-1"></div>
      <div class="box-all-filter">
        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel">Année</span>
          </div>
          <b-form-select
            class="b-form-select-new-style"
            v-model="filterYear"
            :options="ListYear"
            @change="handleChangeYear"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel">Mois</span>
          </div>

          <b-form-select
            class="b-form-select-new-style"
            v-model="filterMonth"
            :options="[
              { value: null, text: 'Tous' },
              ...computedGetMonthForYearTh
            ]"
            text-field="text"
            value-field="value"
            @change="handleChangeMonth"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel">Semaine</span>
          </div>

          <b-form-select
            class="b-form-select-new-style"
            v-model="filterWeek"
            :options="[{ value: null, text: 'Tous' }, ...computedListWeek]"
            text-field="text"
            value-field="value"
            @change="handleChangeWeek"
          ></b-form-select>
        </div>
        <div class="error-message ml-3 ">
          <div v-if="getErrorFraisTh" class="error">
            <ul v-if="Array.isArray(getErrorFraisTh)" class="mb-0">
              <li v-for="(e, index) in getErrorFraisTh" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ getErrorFraisTh }}</div>
          </div>
        </div>
        <div v-if="getLoagingFraisTh" class="chargement ml-1">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>
    <div class="body-box-setting">
      <div class="table-rapport-style w-100-p">
        <b-table
          show-empty
          id="my-table"
          class="custom-table-style table-frais-params"
          :items="computedGetFraisThData"
          :fields="fields"
          bordered
          sticky-header
          hover
          head-variant="light"
          empty-text="Il n'y a aucun enregistrement à afficher"
          tbody-tr-class="ligneEnAttend"
        >
          <template v-slot:cell(Actions)="data">
            <b-button
              class="button-succes-style mt-2 mb-2"
              size="sm"
              variant="success"
              @click.prevent.stop="
                handleUpdate({ item: data.item, type: 'month' })
              "
            >
              <font-awesome-icon icon="pencil-alt" />
            </b-button>
          </template>
          <template v-slot:cell(details)="data">
            <button class="btn btn-anomalie" @click="data.toggleDetails">
              <font-awesome-icon
                class="icon-plus"
                icon="plus-square"
                v-if="data.detailsShowing == false"
              />
              <font-awesome-icon
                class="icon-plus"
                icon="minus-square"
                v-if="data.detailsShowing == true"
              />
            </button>
          </template>
          <template v-slot:cell(month)="data">
            {{ data.item.month_name }}
          </template>
          <template v-slot:cell(operationnel)="data">
            {{ data.item.operationnel }}
          </template>
          <template v-slot:cell(upcube)="data">
            {{ data.item.upcube }}
          </template>
          <template v-slot:cell(pourcentage)="data">
            {{ data.item.pourcentage }}
          </template>
          <template v-slot:cell(commercial)="data">
            {{ data.item.commercial }}
          </template>
          <template v-slot:cell(externe)="data">
            {{ data.item.externe }}
          </template>
          <template v-slot:cell(interne)="data">
            {{ data.item.interne }}
          </template>
          <template v-slot:cell(alteo_commercial)="data">
            {{ data.item.alteo_commercial }}
          </template>
          <template v-slot:cell(alteo_supervision)="data">
            {{ data.item.alteo_supervision }}
          </template>
          <template v-slot:cell(marketing)="data">
            {{ data.item.marketing }}
          </template>
          <template v-slot:cell(technique)="data">
            {{ data.item.technique }}
          </template>
          <template v-slot:cell(rge)="data">
            {{ data.item.rge }}
          </template>
          <template v-slot:cell(CDG)="data">
            {{ data.item.CDG }}
          </template>
          <template v-slot:cell(administratif_sav)="data">
            {{ data.item.administratif_sav }}
          </template>
          <template v-slot:cell(dsi)="data">
            {{ data.item.dsi }}
          </template>
          <template v-slot:cell(juridique)="data">
            {{ data.item.juridique }}
          </template>
          <template v-slot:cell(alger)="data">
            {{ data.item.alger }}
          </template>
          <template v-slot:cell(alteo_control_gestion)="data">
            {{ data.item.alteo_control_gestion }}
          </template>
          <template v-slot:cell(comptabilite)="data">
            {{ data.item.comptabilite }}
          </template>
          <template v-slot:cell(bureau_detude)="data">
            {{ data.item.bureau_detude }}
          </template>
          <template v-slot:cell(mairie)="data">
            {{ data.item.mairie }}
          </template>
          <template v-slot:cell(maintenance_pac)="data">
            {{ data.item.maintenance_pac }}
          </template>
          <template #row-details="data">
            <div
              v-for="(item, index) in data.item.weeks"
              :key="data.item.month_name + index"
            >
              <div class="display-blox">
                <div class="align-td-item background-week">
                  <td class="col-plus item-details-style">
                    <button
                      class="btn btn-anomalie"
                      @click="item.detailsShowing = !item.detailsShowing"
                      :disabled="item.filiales.length > 0 ? false : true"
                    >
                      <font-awesome-icon
                        class="icon-plus-double"
                        icon="plus-square"
                        v-if="item.detailsShowing == false"
                      />
                      <font-awesome-icon
                        class="icon-plus-double"
                        icon="minus-square"
                        v-if="item.detailsShowing == true"
                      />
                    </button>
                  </td>
                  <td class="item-details-style ">
                    Semaine {{ item.week_num }}
                  </td>
                  <td class=" item-details-style ">
                    {{ item.operationnel }}
                  </td>
                  <td class=" item-details-style ">
                    {{ item.commercial }}
                  </td>
                  <td class=" item-details-style ">
                    {{ item.externe }}
                  </td>
                  <td class=" item-details-style ">
                    {{ item.interne }}
                  </td>
                  <td class=" item-details-style ">
                    {{ item.alteo_commercial }}
                  </td>
                  <td class=" item-details-style ">
                    {{ item.alteo_supervision }}
                  </td>
                  <td class=" item-details-style ">
                    {{ item.marketing }}
                  </td>
                  <td class=" item-details-style ">
                    {{ item.technique }}
                  </td>
                  <td class=" item-details-style ">
                    {{ item.rge }}
                  </td>
                  <td class=" item-details-style ">
                    {{ item.CDG }}
                  </td>
                  <td class=" item-details-style ">
                    {{ item.administratif_sav }}
                  </td>
                  <td class=" item-details-style ">
                    {{ item.dsi }}
                  </td>
                  <td class=" item-details-style ">
                    {{ item.juridique }}
                  </td>
                  <td class=" item-details-style ">
                    {{ item.alger }}
                  </td>
                  <td class=" item-details-style ">
                    {{ item.alteo_control_gestion }}
                  </td>
                  <td class=" item-details-style ">
                    {{ item.bureau_detude }}
                  </td>
                  <td class=" item-details-style ">
                    {{ item.mairie }}
                  </td>
                  <td class=" item-details-style ">
                    {{ item.maintenance_pac }}
                  </td>
                  <td class=" item-details-style ">
                    {{ item.upcube }}
                  </td>
                  <td class=" item-details-style tdvalidationFrais">
                    <b-button
                      class="button-succes-style "
                      size="sm"
                      variant="success"
                      @click.prevent.stop="
                        handleUpdate({
                          item: item,
                          type: 'week',
                          data: data.item
                        })
                      "
                    >
                      <font-awesome-icon icon="pencil-alt" />
                    </b-button>
                  </td>
                </div>
                <div
                  class="border-botom"
                  v-if="index < data.item.weeks.length - 1"
                ></div>
              </div>
              <template v-if="item.detailsShowing == true">
                <div
                  v-for="(itemc, indexc) in item.filiales"
                  :key="itemc.month_name + '-filiale-' + indexc"
                >
                  <div class="display-blox">
                    <div class="align-td-item background-filiale">
                      <td class="col-plus item-details-style mr-2">
                        <font-awesome-icon
                          :icon="
                            itemc.detailsShowing == false
                              ? 'angle-right'
                              : 'angle-down'
                          "
                          @click="itemc.detailsShowing = !itemc.detailsShowing"
                        />
                      </td>
                      <td class="item-details-style ">
                        {{ itemc.name }}
                      </td>
                      <td class=" item-details-style ">
                        <!-- {{ itemc.operationnel }} -->
                        -
                      </td>
                      <td class=" item-details-style ">
                        <!-- {{ itemc.commercial }}  -->
                        -
                      </td>
                      <td class=" item-details-style ">
                        -
                      </td>
                      <td class=" item-details-style ">
                        -
                      </td>
                      <td class=" item-details-style ">
                        -
                      </td>
                      <td class=" item-details-style ">
                        -
                      </td>
                      <td class=" item-details-style ">
                        <!-- {{ itemc.marketing }} -->
                        -
                      </td>
                      <td class=" item-details-style ">
                        <!-- {{ itemc.technique }} -->-
                      </td>
                      <td class=" item-details-style ">
                        <!-- {{ itemc.rge }} -->-
                      </td>
                      <td class=" item-details-style ">
                        <!-- {{ itemc.CDG }} -->-
                      </td>
                      <td class=" item-details-style ">
                        <!-- {{ itemc.administratif_sav }} -->-
                      </td>
                      <td class=" item-details-style ">
                        <!-- {{ itemc.dsi }} -->-
                      </td>
                      <td class=" item-details-style ">
                        <!-- {{ itemc.juridique }} -->-
                      </td>
                      <td class=" item-details-style ">
                        <!-- {{ itemc.alger }} -->-
                      </td>
                      <td class=" item-details-style ">
                        <!-- {{ itemc.alteo_control_gestion }} -->-
                      </td>
                      <td class=" item-details-style ">
                        <!-- {{ itemc.bureau_detude }} -->-
                      </td>
                      <td class=" item-details-style ">
                        <!-- {{ itemc.mairie }} -->-
                      </td>
                      <td class=" item-details-style ">
                        <!-- {{ itemc.maintenance_pac }} -->-
                      </td>
                      <td class=" item-details-style ">
                        <!-- {{ itemc.upcube }} -->
                        -
                      </td>
                      <td class=" item-details-style tdvalidationFrais ">
                        <b-button
                          class="button-succes-style "
                          size="sm"
                          variant="success"
                          @click.prevent.stop="
                            handleUpdate({
                              item: itemc,
                              type: 'filiale',
                              data: data.item,
                              ParentItem: item
                            })
                          "
                        >
                          <font-awesome-icon icon="pencil-alt" />
                        </b-button>
                      </td>
                    </div>
                    <div
                      class="border-botom"
                      v-if="index < item.filiales.length - 1"
                    ></div>
                  </div>
                  <template v-if="itemc.detailsShowing == true">
                    <div
                      v-for="(itemd, indexd) in itemc.depots"
                      :key="itemd.month_name + '-depot-' + indexd"
                    >
                      <div class="display-blox">
                        <div class="align-td-item background-depot">
                          <td class="col-plus item-details-style mr-2">
                            <font-awesome-icon icon="align-left" />
                          </td>
                          <td class="item-details-style ">
                            {{ itemd.depot }}
                          </td>
                          <td class=" item-details-style ">
                            {{ itemd.operationnel }}
                          </td>
                          <td class=" item-details-style ">
                            {{ itemd.commercial }}
                          </td>
                          <td class=" item-details-style ">
                            {{ itemd.externe }}
                          </td>
                          <td class=" item-details-style ">
                            {{ itemd.interne }}
                          </td>
                          <td class=" item-details-style ">
                            {{ itemd.alteo_commercial }}
                          </td>
                          <td class=" item-details-style ">
                            {{ itemd.alteo_supervision }}
                          </td>
                          <td class=" item-details-style ">
                            {{ itemd.marketing }}
                          </td>
                          <td class=" item-details-style ">
                            {{ itemd.technique }}
                          </td>
                          <td class=" item-details-style ">
                            {{ itemd.rge }}
                          </td>
                          <td class=" item-details-style ">
                            {{ itemd.CDG }}
                          </td>
                          <td class=" item-details-style ">
                            {{ itemd.administratif_sav }}
                          </td>
                          <td class=" item-details-style ">
                            {{ itemd.dsi }}
                          </td>
                          <td class=" item-details-style ">
                            {{ itemd.juridique }}
                          </td>
                          <td class=" item-details-style ">
                            {{ itemd.alger }}
                          </td>
                          <td class=" item-details-style ">
                            {{ itemd.alteo_control_gestion }}
                          </td>
                          <td class=" item-details-style ">
                            {{ itemd.bureau_detude }}
                          </td>
                          <td class=" item-details-style ">
                            {{ itemd.mairie }}
                          </td>
                          <td class=" item-details-style ">
                            {{ itemd.maintenance_pac }}
                          </td>
                          <td class=" item-details-style ">
                            {{ itemd.upcube }}
                          </td>
                          <td class=" item-details-style tdvalidationFrais ">
                            <b-button
                              class="button-succes-style "
                              size="sm"
                              variant="success"
                              @click.prevent.stop="
                                handleUpdate({
                                  item: itemd,
                                  itemf: itemc,
                                  type: 'depot',
                                  data: item,
                                  ParentItem: data.item
                                })
                              "
                            >
                              <font-awesome-icon icon="pencil-alt" />
                            </b-button>
                          </td>
                        </div>
                        <div class="border-botom"></div>
                      </div>
                    </div>
                  </template>
                </div>
              </template>
            </div>
          </template>
        </b-table>
        <div class="footer-style mt-2">
          <b-pagination
            v-model="page"
            :total-rows="getTotalRowFraisTh"
            :per-page="perPage"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination>

          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="perPage"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
        </div>
      </div>

      <b-modal
        id="ModelConfigRegie"
        ref="ModelConfigRegie"
        title="Configuration"
        no-close-on-backdrop
        :hide-footer="true"
        :hide-header="true"
        @hidden="resetModal"
        modal-class="cutsom-modal-bootstrap custom-modal-size"
      >
        <div class="hader mb-3">
          <div class="titleSetting">Configuration</div>
          <div class="iconClose" @click.prevent="hideModal('ModelConfigRegie')">
            <font-awesome-icon icon="times" />
          </div>
        </div>
        <Card>
          <template v-slot:body>
            <form
              @submit.prevent="handleSubmitUpdateTarif"
              v-if="FraisToUpdate"
              class="form-modal-custom-style"
            >
              <div class="ModelCol mr-3">
                <b-form-group
                  v-if="FraisToUpdate.month_name"
                  label="Année"
                  label-for="year"
                  class="input-modal-champ "
                >
                  <b-form-input
                    autocomplete="off"
                    id="year"
                    v-model="FraisToUpdate.year"
                    disabled
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  v-if="FraisToUpdate.month_name"
                  label="Mois"
                  label-for="month"
                  class="input-modal-champ "
                >
                  <b-form-input
                    autocomplete="off"
                    id="month"
                    v-model="FraisToUpdate.month_name"
                    disabled
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  v-if="FraisToUpdate.week_num"
                  label="Semaine"
                  label-for="week"
                  class="input-modal-champ "
                >
                  <b-form-input
                    autocomplete="off"
                    id="week"
                    v-model="FraisToUpdate.week_num"
                    disabled
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  v-if="FraisToUpdate.filiale"
                  label="Filiale"
                  label-for="filiale"
                  class="input-modal-champ "
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale"
                    v-model="FraisToUpdate.filiale"
                    disabled
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  v-if="FraisToUpdate.depot"
                  label="Dépot"
                  label-for="depot"
                  class="input-modal-champ "
                >
                  <b-form-input
                    autocomplete="off"
                    id="depot"
                    v-model="FraisToUpdate.depot"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="card custom-card-gestion-params-frais">
                <div class="hr-custom-double hr-custom-double-cactus">
                  CACTUS
                </div>
                <div class="ModelCol">
                  <b-form-group
                    label="Commercial %"
                    label-for="Commercial"
                    class="input-modal-champ "
                  >
                    <b-form-input
                      autocomplete="off"
                      id="Commercial"
                      v-model="FraisToUpdate.commercial"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="LAV Régie Commerciale EXTERNE"
                    label-for="externe"
                    class="input-modal-champ "
                  >
                    <b-form-input
                      autocomplete="off"
                      id="externe"
                      v-model="FraisToUpdate.externe"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="ALTEO FRANCE Régie Commerciale INTERNE"
                    label-for="interne"
                    class="input-modal-champ "
                  >
                    <b-form-input
                      autocomplete="off"
                      id="interne"
                      v-model="FraisToUpdate.interne"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="ModelCol">
                  <b-form-group
                    label="ALTEO FRANCE Support Commercial"
                    label-for="alteo_commercial"
                    class="input-modal-champ "
                  >
                    <b-form-input
                      autocomplete="off"
                      id="alteo_commercial"
                      v-model="FraisToUpdate.alteo_commercial"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="ALTEO FRANCE Supervision Confirmation"
                    label-for="alteo_supervision"
                    class="input-modal-champ "
                  >
                    <b-form-input
                      autocomplete="off"
                      id="alteo_supervision"
                      v-model="FraisToUpdate.alteo_supervision"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
              <div class="card custom-card-gestion-params-frais mt-3">
                <div class="hr-custom-double hr-custom-double-cactus">WA</div>
                <div class="ModelCol">
                  <b-form-group
                    label="DSI %"
                    label-for="DSI"
                    class="input-modal-champ "
                  >
                    <b-form-input
                      autocomplete="off"
                      id="DSI"
                      v-model="FraisToUpdate.dsi"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Upcube %"
                    label-for="upcube"
                    class="input-modal-champ "
                  >
                    <b-form-input
                      autocomplete="off"
                      id="upcube"
                      v-model="FraisToUpdate.upcube"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
              <div class="ModelCol">
                <b-form-group
                  label="Opérationnel %"
                  label-for="Opérationnel"
                  class="input-modal-champ "
                >
                  <b-form-input
                    autocomplete="off"
                    id="Opérationnel"
                    v-model="FraisToUpdate.operationnel"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label="Marketing %"
                  label-for="marketing"
                  class="input-modal-champ "
                >
                  <b-form-input
                    autocomplete="off"
                    id="marketing"
                    v-model="FraisToUpdate.marketing"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Technique %"
                  label-for="technique"
                  class="input-modal-champ "
                >
                  <b-form-input
                    autocomplete="off"
                    id="technique"
                    v-model="FraisToUpdate.technique"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="ModelCol">
                <b-form-group
                  label="RGE %"
                  label-for="RGE"
                  class="input-modal-champ "
                >
                  <b-form-input
                    autocomplete="off"
                    id="RGE"
                    v-model="FraisToUpdate.rge"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="CDG %"
                  label-for="CDG"
                  class="input-modal-champ "
                >
                  <b-form-input
                    autocomplete="off"
                    id="CDG"
                    v-model="FraisToUpdate.CDG"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="administratif SAV %"
                  label-for="administratif_sav"
                  class="input-modal-champ "
                >
                  <b-form-input
                    autocomplete="off"
                    id="administratif_sav"
                    v-model="FraisToUpdate.administratif_sav"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>

              <div class="ModelCol">
                <b-form-group
                  label="Juridique %"
                  label-for="juridique"
                  class="input-modal-champ "
                >
                  <b-form-input
                    autocomplete="off"
                    id="juridique"
                    v-model="FraisToUpdate.juridique"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Alger %"
                  label-for="alger"
                  class="input-modal-champ "
                >
                  <b-form-input
                    autocomplete="off"
                    id="alger"
                    v-model="FraisToUpdate.alger"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Alteo contrôle qualité"
                  label-for="controle_gestion"
                  class="input-modal-champ "
                >
                  <b-form-input
                    autocomplete="off"
                    id="controle_gestion"
                    v-model="FraisToUpdate.alteo_control_gestion"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>

              <div class="ModelCol">
                <b-form-group
                  label="Bureau d'étude (Forfaitaire)"
                  label-for="bureau_detude"
                  class="input-modal-champ "
                >
                  <b-form-input
                    autocomplete="off"
                    id="bureau_detude "
                    v-model="FraisToUpdate.bureau_detude"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Mairie (Forfaitaire)"
                  label-for="mairie"
                  class="input-modal-champ "
                >
                  <b-form-input
                    autocomplete="off"
                    id="mairie"
                    v-model="FraisToUpdate.mairie"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Maintenace PAC (Forfaitaire)"
                  label-for="maintenance_pac"
                  class="input-modal-champ "
                >
                  <b-form-input
                    autocomplete="off"
                    id="maintenance_pac "
                    v-model="FraisToUpdate.maintenance_pac"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>

              <div class="actionModel">
                <div class="messageError">
                  <div v-if="error" class="error">
                    <ul v-if="Array.isArray(error)" class="mb-0">
                      <li v-for="(e, index) in error" :key="index">
                        {{ e }}
                      </li>
                    </ul>
                    <div v-else>{{ error }}</div>
                  </div>
                </div>
                <b-button type="submit" class="button-valide-style ">
                  <span>
                    Valider
                    <div v-if="loading" class="loading ml-2">
                      <div class="spinner-border" role="status"></div>
                    </div>
                  </span>
                </b-button>
              </div>
            </form>
          </template>
        </Card>
      </b-modal>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Gestion-des-variables-frais',
  data() {
    return {
      FraisToConfig: {
        year: null,
        month: null,
        operationnel: null,
        upcube: null,
        commercial: null,
        marketing: null,
        technique: null,
        rge: null,
        CDG: null,
        administratif_sav: null,
        dsi: null,
        juridique: null,
        alger: null,
        //forfaitaire
        bureau_detude: null,
        mairie: null,
        maintenance_pac: null,
        week: null
      },
      filterYear: null,
      filterMonth: null,
      filterWeek: null,
      ListYear: [],
      error: null,
      loading: false,
      perPage: 10,
      page: 1,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ],
      fields: [
        {
          key: 'details',
          label: '',
          tdClass: 'color-params-frais',
          thClass: 'th-color'
        },

        { key: 'month', label: 'Mois' },
        { key: 'operationnel', label: 'Opérationnel' },
        { key: 'commercial', label: 'Commercial' },
        { key: 'externe', label: 'LAV Régie Commerciale EXTERNE' },
        { key: 'interne', label: 'ALTEO FRANCE Régie Commerciale INTERNE' },
        { key: 'alteo_commercial', label: 'ALTEO FRANCE Support Commercial' },
        {
          key: 'alteo_supervision',
          label: 'ALTEO FRANCE Supervision Confirmation'
        },
        { key: 'marketing', label: 'Marketing' },
        { key: 'technique', label: 'Technique' },
        { key: 'rge', label: 'RGE' },
        { key: 'CDG', label: 'CDG' },
        { key: 'administratif_sav', label: 'Administratif SAV' },
        { key: 'dsi', label: 'DSI' },
        { key: 'juridique', label: 'Juridique' },
        { key: 'alger', label: 'Alger' },
        { key: 'alteo_control_gestion', label: 'Alteo contrôle qualité' },
        { key: 'bureau_detude', label: "Bureau d'étude" },
        { key: 'mairie', label: 'Mairie' },
        { key: 'maintenance_pac', label: 'Maintenace PAC' },
        { key: 'upcube', label: 'Upcube' },

        {
          key: 'Actions',
          label: 'Actions',
          thClass: 'thvalidation',
          tdClass: 'tdvalidation'
        }
      ],
      FraisToUpdate: null,
      FraisToUpdateCopier: null,
      FraisToUpdateType: null
    };
  },
  methods: {
    ...mapActions([
      'fetchMonthForYearTH',
      'newConfigFraisTh',
      'fetchAllFraisTh',
      'updateConfigParamsFraisTh'
    ]),
    async handleSubmitConfigParamsFrais() {
      this.loading = true;
      const response = await this.newConfigFraisTh(this.FraisToConfig);
      if (response.succes) {
        this.hideModal('ConfigurationParamsFrais');
      } else {
        this.error = response.error;
        this.loading = false;
      }
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    resetModal() {
      this.error = null;
      this.loading = false;
      this.FraisToConfig = {
        year: null,
        month: null,
        operationnel: null,
        upcube: null,
        commercial: null,
        marketing: null,
        technique: null,
        rge: null,
        CDG: null,
        administratif_sav: null,
        dsi: null,
        juridique: null,
        alger: null,
        //forfaitaire
        bureau_detude: null,
        mairie: null,
        maintenance_pac: null,
        week: null
      };
      this.FraisToUpdate = null;
      this.FraisToUpdateCopier = null;
      this.FraisToUpdateType = null;
      this.fetchMonthForYearTH(this.filterYear);
    },
    handleChangeYearModel() {
      this.FraisToConfig.month = null;
      this.FraisToConfig.week = null;
      this.fetchMonthForYearTH(this.FraisToConfig.year);
      this.setLocalStorageParamsFraisTh();
    },
    handleChange(payload) {
      if (payload && payload.champName && this[payload.champName]) {
        this[payload.champName] = payload.value;
      }
      this.filterTable();
      this.setLocalStorageParamsFraisTh();
    },
    handleChangeMonth() {
      this.filterWeek = null;
      this.filterTable();
      this.setLocalStorageParamsFraisTh();
    },
    handleChangeWeek() {
      this.filterTable();
      this.setLocalStorageParamsFraisTh();
    },
    handleChangeYear() {
      this.filterMonth = null;
      this.filterWeek = null;
      this.fetchMonthForYearTH(this.filterYear);
      this.filterTable();
      this.setLocalStorageParamsFraisTh();
    },
    changePerPage() {
      this.page = 1;
      this.filterTable();
      this.setLocalStorageParamsFraisTh();
    },
    pagination(paginate) {
      this.page = paginate;
      this.filterTable();
      this.setLocalStorageParamsFraisTh();
    },
    filterTable() {
      this.fetchAllFraisTh({
        year: this.filterYear,
        month: this.filterMonth,
        per_page: this.perPage,
        page: this.page,
        week: this.filterWeek
      });
      this.setLocalStorageParamsFraisTh();
    },
    handleUpdate(item) {
      this.FraisToUpdateCopier =
        item.type == 'week' || item.type == 'filiale' ? item.data : item.item;
      this.FraisToUpdateType = item.type;
      this.FraisToUpdate = { ...item.item };
      if (item.type == 'week') {
        this.FraisToUpdate.year = item.data.year;
        this.FraisToUpdate.month_name = item.data.month_name;
      }
      if (item.type == 'filiale') {
        this.FraisToUpdate.week_num = item.ParentItem.week_num;
        this.FraisToUpdate.week = item.ParentItem.id;
        this.FraisToUpdate.year = item.data.year;
        this.FraisToUpdate.month_name = item.data.month_name;
        this.FraisToUpdate.filiale = item.item.name;
      }
      if (item.type == 'depot') {
        this.FraisToUpdate.week_num = item.data.week_num;
        this.FraisToUpdate.year = item.ParentItem.year;
        this.FraisToUpdate.month_name = item.ParentItem.month_name;
      }
      this.$refs['ModelConfigRegie'].show();
    },
    async handleSubmitUpdateTarif() {
      this.loading = true;
      const response = await this.updateConfigParamsFraisTh({
        data: this.FraisToUpdateCopier,
        fraisToUpdate: this.FraisToUpdate,
        type: this.FraisToUpdateType
      });
      if (response.succes) {
        this.hideModal('ModelConfigRegie');
      } else {
        this.error = response.eror;
        this.loading = false;
      }
    },
    setLocalStorageParamsFraisTh() {
      localStorage.setItem(
        'gestion-params-frais-th',
        JSON.stringify({
          year: this.filterYear,
          month: this.filterMonth,
          week: this.filterWeek
        })
      );
    }
  },
  computed: {
    ...mapGetters([
      'getMonthForYearTh',
      'getLoagingFraisTh',
      'getErrorFraisTh',
      'getTotalRowFraisTh',
      'getFraisThData'
    ]),
    computedGetMonthForYearTh() {
      const tab = this.getMonthForYearTh.map(item => ({
        value: item.month_name,
        text: item.month_name
      }));
      return tab;
    },
    computedGetFraisThData() {
      if (this.filterMonth != null) {
        return this.getFraisThData.filter(
          item => item.month_name == this.filterMonth
        );
      }
      return this.getFraisThData;
    },
    computedListWeekModal() {
      if (this.FraisToConfig.month != null && this.FraisToConfig.year != null) {
        let tab = this.getMonthForYearTh.filter(
          item => item.month_name == this.FraisToConfig.month
        );
        const tab1 = tab[0].weeks.map(item => ({
          value: item.id,
          text: 's' + item.num
        }));
        return tab1;
      }
      return [];
    },
    computedListWeek() {
      if (this.filterMonth != null) {
        let tab = this.getMonthForYearTh.filter(
          item => item.month_name == this.filterMonth
        );
        const tab1 = tab[0].weeks.map(item => ({
          value: item.id,
          text: 's' + item.num
        }));
        return tab1;
      }
      let tab2 = [];
      const tab1 = this.getMonthForYearTh.map(items => {
        items.weeks.map(item =>
          tab2.push({
            value: item.id,
            text: 's' + item.num
          })
        );
      });
      return tab2;
    }
  },
  components: {
    Card: () => import('../../component/card.vue')
  },
  async mounted() {
    var courantYear = moment().year();
    this.filterYear = courantYear;

    if (localStorage.getItem('gestion-params-frais-th')) {
      this.filteryear = JSON.parse(
        localStorage.getItem('gestion-params-frais-th')
      ).year;
    }
    await this.fetchMonthForYearTH(this.filteryear);
    var courantMonthId = moment().month();
    var courantMonth = this.getMonthForYearTh[courantMonthId]
      ? this.getMonthForYearTh[courantMonthId].month_name
      : this.filterMonth;

    if (localStorage.getItem('gestion-params-frais-th')) {
      this.filterMonth = JSON.parse(
        localStorage.getItem('gestion-params-frais-th')
      ).month;
      this.filterWeek = JSON.parse(
        localStorage.getItem('gestion-params-frais-th')
      ).week;
    } else {
      this.year = courantYear;
      this.filterMonth = courantMonth;
      this.setLocalStorageParamsFraisTh();
    }
    if (sessionStorage.getItem('page-gestion-params-frais-th')) {
      this.page = sessionStorage.getItem('page-gestion-params-frais-th');
    } else {
      sessionStorage.setItem('page-gestion-params-frais-th', this.page);
    }
    for (var i = 2019; i <= courantYear; i++) {
      this.ListYear.push({ value: i, text: i });
    }
    this.fetchMonthForYearTH(this.filterYear);
    this.filterTable();
  }
};
</script>

<style scoped lang="scss">
.contentConfigParamsFraisTh {
  padding: 15px;
  padding-top: 5px;
  overflow: hidden;
  width: 100%;
  .icon-plus {
    font-size: 13px;
    color: #1d1aab;
    &:hover {
      color: #4d4bac;
    }
  }
  .icon-plus-double {
    font-size: 13px;
    color: white;
    &:hover {
      color: #4d4bac;
    }
  }
  .background-week {
    background-color: #d1d1e9;
    color: #fff;
    td {
      padding: 0px;
      margin: 0px;
      font-size: 9.5px;
    }
    .button-succes-style {
      background-color: #6d6c99;
      padding: 6px 11px;
      margin: 2px 0px;
    }
    .col-plus {
      min-width: 42px !important;
      position: sticky;
      left: -10px !important;
      background-color: #d1d1e9;
    }
    .item-details-style {
      align-self: center;
    }
  }
  .background-depot {
    background-color: #8e8dd8;
    td {
      padding: 0px;
      margin: 0px;
      font-size: 9.5px;
    }
    .button-succes-style {
      background-color: #fff;
      color: #4d4bac;
      padding: 6px 11px;
      margin: 2px 0px;
    }
    .col-plus {
      min-width: 42px !important;
      position: sticky;
      left: -10px !important;
      background-color: #8e8dd8;
    }
    .item-details-style {
      align-self: center;
      color: #fff;
    }
  }
  .background-filiale {
    background-color: #b0afd9;
    td {
      padding: 0px;
      margin: 0px;
      font-size: 9.5px;
    }
    .button-succes-style {
      background-color: #d4d4ff;
      color: #4d4bac;
      padding: 6px 11px;
      margin: 2px 0px;
    }
    .col-plus {
      min-width: 42px !important;
      position: sticky;
      left: -10px !important;
      background-color: #b0afd9;
    }
    .item-details-style {
      align-self: center;
      color: #fff;
    }
  }
  .border-botom {
    border-bottom: 1px solid #f6f5fb;
  }
  .body-box-setting {
    height: calc(100vh - 194px) !important;
  }
  .table-frais-params {
    max-height: calc(100vh - 280px) !important;
    height: calc(100vh - 280px) !important;
    margin-bottom: 0px;
  }
  .chargement {
    font-size: 8px;
    .spinner-border {
      width: 1rem;
      height: 1rem;
    }
  }
}
</style>
<style lang="scss">
.custom-card-gestion-params-frais {
  padding: 5px;
}
.hr-custom-double {
  position: relative;
  top: -16px;
  font-weight: 700;
  font-size: 14px;
  color: #282668;
  background-color: white;
  width: 67px;
  padding: 0px;
  margin-left: 5px;
}

.table-frais-params {
  .thvalidation,
  .tdvalidation,
  .tdvalidationFrais {
    min-width: 107px !important;
  }
  .background-week {
    .tdvalidationFrais {
      background-color: #d1d1e9 !important;
      position: sticky;
      right: -10px !important;
    }
  }
  .background-depot {
    .tdvalidationFrais {
      background-color: #8e8dd8 !important;
      position: sticky;
      right: -10px !important;
    }
  }
  .background-filiale {
    .tdvalidationFrais {
      background-color: #b0afd9 !important;
      position: sticky;
      right: -10px !important;
    }
  }
}
</style>
